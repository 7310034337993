exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-lm-js": () => import("./../../../src/pages/assets/lm.js" /* webpackChunkName: "component---src-pages-assets-lm-js" */),
  "component---src-pages-blog-tags-js": () => import("./../../../src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-policy-privacy-en-js": () => import("./../../../src/pages/policy/privacy-en.js" /* webpackChunkName: "component---src-pages-policy-privacy-en-js" */),
  "component---src-pages-policy-privacy-pl-js": () => import("./../../../src/pages/policy/privacy-pl.js" /* webpackChunkName: "component---src-pages-policy-privacy-pl-js" */),
  "component---src-pages-solid-js": () => import("./../../../src/pages/solid.js" /* webpackChunkName: "component---src-pages-solid-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

